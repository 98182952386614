import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./global.css";

import * as serviceWorker from "serviceWorker";

import React from "react";
import Root from "components/Root";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import configureStore from "store/configureStore";
import { createBrowserHistory } from "history";
import { reactLocalStorage } from "reactjs-localstorage";
import { render } from "react-dom";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const initialState = {};
const store = configureStore(initialState, history);

const themes = {
  authenticity: `${process.env.PUBLIC_URL}/themes/authenticity-theme.css`,
  eco: `${process.env.PUBLIC_URL}/themes/eco-theme.css`,
  mc: `${process.env.PUBLIC_URL}/themes/mc-theme.css`,
  turistec: `${process.env.PUBLIC_URL}/themes/turistec-theme.css`,
  aeat: `${process.env.PUBLIC_URL}/themes/aeat-theme.css`,
};

let defaultTheme = "eco"; //eco o aeat
if (
  window.location.hostname === "app.pangeanic.com" ||
  window.location.hostname === "gui-pan.trsb.com" ||
  window.location.hostname === "pgweb.pangeanic.com"
) {
  defaultTheme = "eco";
} else if (window.location.hostname === "turistec.pangeamt.com") {
  defaultTheme = "turistec";
} else if (window.location.hostname === "authenticity.pangeamt.com") {
  defaultTheme = "authenticity";
} else if (window.location.hostname === "aeat.pangeamt.com") {
  defaultTheme = "aeat";
} else if (window.location.hostname.includes("mt.mc-lehm.com")) {
  defaultTheme = "mc";
}
console.log("Theme: ", defaultTheme);

reactLocalStorage.set("defaultTheme", defaultTheme);

render(
  <ThemeSwitcherProvider themeMap={themes} defaultTheme={defaultTheme}>
    <Root store={store} history={history} />
  </ThemeSwitcherProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
